// 客製化：支援 CoolBe(Wyeth)

import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import type { CoolBeWyethTemplateType } from '@shared/api/rtkQuery'
import { useGetCoolBeWyethTemplateListQuery } from '@shared/api/rtkQuery'
import DropdownList, { type Option } from '@shared/ui/Dropdown/DropdownList'
import { DataTable } from '@shared/ui/table'
import {
  INIT_PAGE,
  LIST_PER_PAGE,
} from '@widgets/workflow/_shared/nodes/_shared/constants'
import { FieldGrid } from '@widgets/workflow/_shared/nodes/_shared/styles'
import type { CoolbeWyethConfig } from '@widgets/workflow/_shared/nodes/ActionSendLineNode/actionSendLineNodeSchema'
import { ROWS_PER_PAGE_OPTIONS } from '@widgets/workflow/_shared/nodes/ActionSendLineNode/templates/_shared/constants'

import useColumns from './useColumns'

type Props = {
  coolBeWyethTemplateConfig?: CoolbeWyethConfig
  integrationId: number
  isEditable: boolean
  setTemplateConfig: (config: CoolbeWyethConfig) => void
}

export const CoolBeWyeth = memo(
  ({
    coolBeWyethTemplateConfig,
    integrationId,
    isEditable,
    setTemplateConfig,
  }: Props) => {
    const { t } = useTranslation(['workflow'])
    const [page, setPage] = useState<number>(INIT_PAGE)
    const [perPage, setPerPage] = useState<number>(LIST_PER_PAGE)

    // 向前相容，預設為 system_template
    const [messageTemplateType, setMessageTemplateType] =
      useState<CoolBeWyethTemplateType>(
        coolBeWyethTemplateConfig?.messageTemplateType || 'system_template'
      )

    const columns = useColumns({
      templateId: coolBeWyethTemplateConfig?.templateId,
    })

    const {
      data: coolBeWyethTemplateListData = [],
      isFetching: isFetchingTemplateList,
      isError: isErrorTemplateList,
    } = useGetCoolBeWyethTemplateListQuery({
      integrationId,
      typeTemplate: messageTemplateType,
    })

    const Options: Option[] = [
      {
        label: t('workflow:node_action_line_push_template_type.coolbe.system'),
        value: 'system_template',
      },
      {
        label: t(
          'workflow:node_action_line_push_template_type.coolbe.combined'
        ),
        value: 'combined_template',
      },
    ]

    const dataTableRows = useMemo(() => {
      const startIndex = (page - 1) * perPage
      const endIndex = startIndex + perPage

      return coolBeWyethTemplateListData
        .map(x => ({
          ...x,
          isDisabled: !isEditable,
        }))
        .slice(startIndex, endIndex)
    }, [page, perPage, coolBeWyethTemplateListData, isEditable])

    useEffect(() => {
      const handleTemplateInitialization = () => {
        if (
          dataTableRows.length > 0 &&
          !coolBeWyethTemplateConfig &&
          !isFetchingTemplateList &&
          !isErrorTemplateList
        ) {
          setTemplateConfig({
            templateId: dataTableRows[0].id,
            messageTemplateType: 'system_template',
          })
        }
      }

      const handleTemplateTypeChange = () => {
        if (
          !isFetchingTemplateList &&
          !isErrorTemplateList &&
          dataTableRows.length > 0 &&
          coolBeWyethTemplateListData.length > 0
        ) {
          if (
            coolBeWyethTemplateConfig?.messageTemplateType !==
            messageTemplateType
          ) {
            setTemplateConfig({
              templateId: dataTableRows[0].id,
              messageTemplateType,
            })
          }
        }
      }

      handleTemplateInitialization()
      handleTemplateTypeChange()
    }, [
      dataTableRows,
      coolBeWyethTemplateListData,
      coolBeWyethTemplateConfig,
      isFetchingTemplateList,
      isErrorTemplateList,
      messageTemplateType,
      setTemplateConfig,
    ])

    if (isErrorTemplateList) {
      return null
    }

    return (
      <>
        <FieldGrid mb={2} isHideBorder>
          <Box fontWeight="medium">
            {t('workflow:node_action_line_template')}
          </Box>

          <DropdownList
            popperDisablePortal
            options={Options}
            disabled={!isEditable || isFetchingTemplateList}
            value={messageTemplateType}
            onValueChanged={option => {
              setMessageTemplateType(option.value as CoolBeWyethTemplateType)
              setPage(INIT_PAGE)
            }}
          />
        </FieldGrid>

        <Box mb={2}>
          <DataTable
            columns={columns}
            containerType="border"
            count={coolBeWyethTemplateListData.length}
            isLoading={isFetchingTemplateList}
            isRowClickable
            page={page - 1}
            rows={dataTableRows}
            rowsPerPage={perPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onPageChange={newPage => setPage(newPage + 1)}
            onRowsPerPageChange={(rowsPerPage: number) => {
              setPage(1)
              setPerPage(rowsPerPage)
            }}
            onRowClick={({ id }) =>
              setTemplateConfig({
                templateId: id,
                messageTemplateType,
              })
            }
          />
        </Box>
      </>
    )
  }
)

export default CoolBeWyeth
